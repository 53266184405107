<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- notifications data-table -->
            <notifications-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></notifications-data-table>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, randomTagStringGenerator } from '@/helpers/utilities'
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
const NotificationsDataTable = () => import('@/components/admin/NotificationsDataTable.vue')


export default {
    components: {
        NotificationsDataTable
    },

    data: () => ({
        //data table settings
        dataTableSettings: {
            id: 'dtNotifications',
            title: 'Seznam obvestil',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "Zap. št.", value: "id", align: "left", visibility: true},
                { text: "Oznaka", value: "tag", visibility: true},
                { text: "Tip dovolilnice", value: "permit_type", visibility: true},
                { text: "Status", value: "notification_status", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/admin/notifications',
            editItem: {
                route: 'admin_notifications_edit',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_NOTIFICATION',
                replaceStrWithColumnValue: 'id',
                confirmationText : "Sta prepričani da želite odstraniti obvestilo #{str_to_replace}?",
                dialogConfirmSuccessText: 'Obvestilo z oznako #{str_to_replace} je bilo uspešno odstranjeno.',
                dialogConfirmFailText: 'Pri odstranjevanju obvestila z oznako #{str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: false
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_notifications',
                totalItems : {
                    event: ''
                },
                search: 'dt-notifications-search',
                toggleColumnsId: 'toggle-notifications-list-columns'
            }


        },
    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                
            }
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    }
}
</script>

<style scoped>
</style>